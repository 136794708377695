@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: 'Inter', sans-serif;
}

html {
  background-color: #F4F4F4;
}

body {
  max-width: 550px;
  margin: 0 auto;
}

.container {
  margin: 0 16px;
}

::-webkit-scrollbar {
  width: 0;
}

:root {
  --loader-background-color: #EEEEEE;
  --loader-highlight-color: #DEDEDE;
}

.time-input::-webkit-calendar-picker-indicator {
  filter: invert(50%) !important;
}

.datepicker-container {
  position: relative;
  display: inline-block;
}

.calendar {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 5;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar table {
  width: 100%;
  border-collapse: collapse;
}

.calendar th, .calendar td {
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.calendar td:hover {
  background-color: #f0f0f0;
}

.calendar .selected {
  background-color: #007bff;
  color: white;
}

.calendar button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.calendar button:focus {
  outline: none;
}

.btn-loader {
  position: absolute;
  width: 22px;
  height: 22px;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin .8s linear infinite;
  left: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}